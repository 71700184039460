import * as React from "react";

const SvgCross = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="filled"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 23.41"
    fill="#000"
    {...props}
  >
    <path  fill="#000" d="M24 22.56l-11.29-11.3L23.11.85 22.26 0 11.85 10.41 1.68.24l-.85.85L11 11.26l-11 11 .85.85 11-11 11.3 11.29.85-.85z" />
  </svg>
);

export default SvgCross;
