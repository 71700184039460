import { stringify } from "utils/query-string";
import { SearchSections } from "./interfaces/search.interfaces";

export const searchRoute = {
  path: `/search/:section`,
  link: (section: SearchSections, query?: any) => {
    const queryString = query ? `?${stringify(query)}` : "";
    return `/search/${section}${queryString}`;
  },
};
