import React, { useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom";
export interface PortalProps {
  parentEl: HTMLElement;
  children?: React.ReactNode;
}

export const Portal = ({ parentEl, children }: PortalProps) => {
  const [el] = useState<HTMLElement>(() => document.createElement("div"));

  useLayoutEffect(() => {
    const root = parentEl;
    root.appendChild(el);

    return () => {
      root.removeChild(el);
    };
  }, [el, parentEl]);

  if (el) {
    return ReactDOM.createPortal(children, el);
  }
  return null;
};
