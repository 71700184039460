import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import SvgPlanArrow from "components/icons/PlanArrow";
import { artworkRouteConfig } from "features/Artwork/artwork.routes";
import SubheaderBase from "features/Header/SubheaderBase/SubheaderBase";
import useMobileMenu from "features/MobileMenu/hooks/useMobileMenu";
import NavigationLink from "features/Navigation/components/NavigationLink";
import { viewingRoomRoutes } from "features/ViewingRoom/viewing-room.routes";
import React, { useCallback } from "react";
import { customProps, secondaryFont, zIndex } from "styles";
import { down, up } from "styles/utils/breakpoints";
import em from "styles/utils/em";
import useBreakpoint from "styles/utils/useBreakpoint";
// import getFullName from "utils/getFullName";
import useShallowSearch from "../../features/Search/hooks/useShallowSearch";

const Root = styled.div`
  position: absolute;
  width: 100%;
  z-index: ${zIndex.header - 5};
  left: 50%;
  transform: translateX(-50%);
  /* right: 0; */
  border-radius: 0px 0px 10px 10px;

  background-color: #fff;
  color: #000;

  ${up("lg")} {
    padding: 0;
    max-width: 600px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0;
  background-color: #fff;
  border-radius: 0px 0px 10px 10px;

  ${up("lg")} {
    padding-left: var(${customProps.layoutPl});
    padding-right: var(${customProps.layoutPr});
    padding: 20px;
  }
`;

const ResultItems = styled.div`
  padding-inline: 30px;
  color: #000;
  padding-top: 20px;

  ${up('lg')} {
    padding-top: 0;
  }
`;

const NotFound = styled.span`
  font-size: 14px;
  display: none;
  text-transform: uppercase;
  ${up("md")} {
    display: initial;
  }
`;

const ResultsType = styled.span`
  font-family: ${secondaryFont};
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;

  ${up("lg")} {
    font-size: 16px;
  }
`;

const resultLinkCss = css`
  display: block;
  color: #000000;
  text-align: left;

  font-size: 16px;
  text-decoration: none;
  line-height: ${em("19px", "14px")};
  letter-spacing: ${em("0.78px", "14px")};

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  & + & {
    margin-top: 10px;
  }
`;

const ItemName = styled.span`
  text-transform: uppercase;
`;

const Subheader = styled(SubheaderBase)`
  display: flex;
  justify-content: center;
  border-radius: 0px 0px 10px 10px;
  border: none;
`;

const NothingFound = styled.div`
  text-transform: uppercase;
  text-align: center;
  color: #000;
  font-size: 14px;
`;

const FoundItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-block: 20px;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  padding-inline: 30px;
`;

interface SearchOverlayProps {
  className?: string;
}

const SearchOverlay = ({ className }: SearchOverlayProps) => {
  const {
    searchQuery: { isSuccess, isLoading, data },
    searchOverlayState,
    closeSearch,
    deepSearch,
  } = useShallowSearch();
  const { close } = useMobileMenu();
  const upLg = useBreakpoint(up("lg"));
  const hasResult =
    data?.artworks.length || data?.viewingRooms.length || data?.artists.length;

  const handleDeepSearch = useCallback(() => {
    deepSearch();
    close();
  }, [deepSearch, close]);

  return (
    <Root className={className}>
      {!isLoading && isSuccess && !hasResult && (
        <Subheader>
          <NothingFound>Nothing found</NothingFound>
        </Subheader>
      )}

      {!isLoading && hasResult ? (
        <Content>
          {!upLg && (
            <FoundItems onClick={handleDeepSearch}>
              <span>
                All artworks and galleries for “
                {searchOverlayState.searchString}”
              </span>
              <SvgPlanArrow color="#000" height="10" />
            </FoundItems>
          )}
          <ResultItems>
            {!!data?.artworks.length ? (
              data?.artworks.map((item) => {
                return (
                  <NavigationLink
                    key={item.id}
                    addGoBackState
                    className={resultLinkCss}
                    to={artworkRouteConfig.link(item.viewingRoomId, item.id)}
                    onClick={closeSearch}
                  >
                    <ItemName>{item.name}</ItemName>
                    <br />
                    <ResultsType>Artwork</ResultsType>
                  </NavigationLink>
                );
              })
            ) : (
              <NotFound>Artworks not found</NotFound>
            )}
          </ResultItems>
          <ResultItems>
            {!!data?.viewingRooms.length ? (
              data?.viewingRooms.map((item) => {
                return (
                  <NavigationLink
                    key={item.id}
                    addGoBackState
                    className={resultLinkCss}
                    to={viewingRoomRoutes.link(item.id)}
                    onClick={closeSearch}
                  >
                    <ItemName>{item.name}</ItemName>
                    <br />
                    <ResultsType>Gallery</ResultsType>
                  </NavigationLink>
                );
              })
            ) : (
              <NotFound>Viewing rooms not found</NotFound>
            )}
          </ResultItems>
        </Content>
      ) : null}
    </Root>
  );
};

export default SearchOverlay;
