export interface IToketStorage {
  getToken(): string | null;
  setToken(token: string): void;
  invalidateToken(): void;
}

const storage = sessionStorage;

const previewTokenStorage: IToketStorage = {
  getToken() {
    return storage.getItem("token-preview");
  },
  setToken(token) {
    storage.setItem("token-preview", token);
  },
  invalidateToken() {
    storage.removeItem("token-preview");
  },
};

export default previewTokenStorage;
