import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { parse } from "utils/query-string";
import { PaginationState, SearchState } from "../interfaces/search.interfaces";

const initialPaginationState: PaginationState = {
  skip: 0,
  limit: 20,
};

const initialState: SearchState = {
  name: "",
  priceRanges: [],
  mediums: [],
  centuryRanges: [],
  sections: [],
  regions: [],
  categories: [],
  artistBirthPlaces: [],
  years: [],
  sortBy: "asc",
  artists: undefined,
  isPurchasableOnline: "false",
  noVisited: "false",
  recentlyAdded: "false",
  ...initialPaginationState,
};

export const searchInitialState = initialState;

function parsePagination<T extends PaginationState>(value: T) {
  value.skip = +value.skip;
  value.limit = +value.limit;

  return value;
}

export function getPaginationInfo() {
  const {
    skip = initialPaginationState.skip,
    limit = initialPaginationState.limit,
  }: PaginationState = parse(window.location.search);
  return parsePagination({ skip, limit });
}

export function getSearchState(search: string = window.location.search) {
  const {
    name = initialState.name,
    priceRanges = initialState.priceRanges,
    mediums = initialState.mediums,
    centuryRanges = initialState.centuryRanges,
    sections = initialState.sections,
    regions = initialState.regions,
    categories = initialState.categories,
    artistBirthPlaces = initialState.artistBirthPlaces,
    sortBy = initialState.sortBy,
    isPurchasableOnline = initialState.isPurchasableOnline,
    skip = initialState.skip,
    limit = initialState.limit,
    noVisited = initialState.noVisited,
    recentlyAdded = initialState.recentlyAdded,
    artists,
    years = initialState.years,
  }: Partial<SearchState> = parse(search);
  return parsePagination({
    name,
    priceRanges,
    mediums,
    centuryRanges,
    sections,
    regions,
    categories,
    isPurchasableOnline,
    sortBy,
    skip,
    limit,
    artists,
    artistBirthPlaces,
    noVisited,
    recentlyAdded,
    years
  });
}

export default function useSearchState() {
  const { search } = useLocation();

  return useMemo(() => {
    return getSearchState(search);
  }, [search]);
}
