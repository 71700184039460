
import * as React from "react";


const SvgNorthernTrust = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 360 150" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M27.7495 41.1279L52.9541 72.7549H59.2552L26.3711 31.6398H17.3132V36.3838H22.8267V68.2085H17.3132V72.7549H33.0661V68.2085H27.5525V40.9302L27.7495 41.1279Z" fill="currentColor"/>
    <path d="M64.0109 31.6398H48.2284V36.3838H53.7523V55.1624H58.487V36.3838H64.0109V31.6398Z" fill="currentColor"/>
    <path d="M40.1541 16.4193C37.0035 16.4193 34.4437 13.8496 34.4437 10.6869C34.4437 7.52417 37.0035 4.95447 40.1541 4.95447C43.3047 4.95447 45.8645 7.52417 45.8645 10.6869C45.6676 13.8496 43.1078 16.4193 40.1541 16.4193ZM48.8182 16.4193L49.0151 16.2216C49.9997 14.6403 50.5904 12.6636 50.5904 10.6869C50.5904 4.95447 45.8645 0.210419 40.1541 0.210419C34.4437 0.210419 29.7178 4.95447 29.7178 10.6869C29.7178 12.6636 30.3085 14.6403 31.2931 16.2216L31.49 16.4193H11.602V21.361H37.5943V37.3722L42.517 43.4999V21.5587H68.5093V16.6169H48.8182V16.4193Z" fill="currentColor"/>
    <path d="M40.1544 100.231L33.6564 94.4985H34.0502C36.0193 94.8939 37.9884 95.0915 40.1544 95.0915C42.1235 95.0915 44.0927 94.8939 46.2587 94.4985H46.6525L40.5482 100.231H40.1544ZM74.2201 59.3135C73.4324 66.6272 70.2818 73.7433 65.1621 79.278C59.2548 85.6034 51.3784 89.5568 42.7143 90.1498H42.5174V68.4062L37.5946 62.2785V90.1498H37.3977C28.7336 89.5568 20.6602 85.6034 14.9498 79.278C9.8301 73.7433 6.67952 66.8249 5.89188 59.3135L0.969097 54.9647V55.7554C0.969097 62.8715 2.93821 69.9876 6.67952 76.1153C10.4208 82.2431 15.7374 87.1848 21.8417 90.3475L39.9575 106.359L58.0733 90.3475C64.3745 86.9871 69.6911 82.0454 73.2355 75.9176C77.1737 69.7899 78.9459 62.6738 78.9459 55.5578V54.7671L74.417 59.3135H74.2201Z" fill="currentColor"/>
    <path d="M105.332 70.5805H114.802V95.2891H119.734V70.5805H129.4V66.0341H105.332V70.5805Z" fill="currentColor"/>
    <path d="M141.967 79.871V70.5805H150.647C154.395 70.5805 155.974 72.7549 155.974 75.3246C155.974 77.6966 154.593 79.871 150.647 79.871H141.967ZM160.906 95.8822H163.273V91.7311H161.3C159.525 91.7311 157.946 90.9404 156.565 88.9638C156.565 88.9638 153.803 85.0104 153.606 84.2197C158.144 83.2314 160.906 80.464 160.906 75.5223C160.906 70.7782 157.749 66.2318 150.45 66.2318H136.837V95.4868H141.769V84.8127H147.688C148.082 85.0104 148.28 85.6034 149.069 86.7894C149.661 87.9754 151.436 90.5451 151.436 90.5451C154.198 94.6962 156.368 95.8822 160.708 95.8822H160.906Z" fill="currentColor"/>
    <path d="M188.662 83.0337C188.662 87.9754 185.901 91.1381 181.363 91.1381H180.771C176.234 90.9404 173.669 87.9754 173.669 83.0337V66.0341H168.737V83.0337C168.737 90.5451 173.472 95.6845 180.771 95.6845H181.363C188.662 95.6845 193.594 90.7427 193.594 83.0337V66.0341H188.662V83.0337H189.057H188.662Z" fill="currentColor"/>
    <path d="M213.461 77.6966C209.319 77.1036 206.557 76.1152 206.557 73.7432C206.557 71.1735 209.121 69.5922 213.067 69.5922C216.026 69.5922 218.788 70.7782 220.169 72.7549L220.366 72.9525L224.115 70.3828L223.917 70.1852C221.747 66.8248 218.196 65.2434 213.067 65.2434C209.121 65.2434 205.965 66.4295 203.795 68.4061C202.414 69.7898 201.625 71.5688 201.625 73.7432C201.625 80.2663 207.543 81.65 213.067 82.4406C217.999 83.0336 220.169 84.4173 220.169 86.987C220.169 91.1381 214.645 91.5334 213.067 91.5334C210.7 91.5334 206.557 90.9404 204.189 86.7894L203.992 86.5917L200.244 89.1614L200.441 89.3591C203.006 93.9054 206.951 96.0798 213.067 96.0798C218.985 96.0798 225.101 93.7078 225.298 86.987C225.496 80.2663 219.774 78.6849 213.659 77.6966H213.461Z" fill="currentColor"/>
    <path d="M230.765 70.5805H240.234V95.2891H245.363V70.5805H254.833V66.0341H230.765V70.5805Z" fill="currentColor"/>
    <path d="M179.774 36.7793V27.4888H188.455C192.203 27.4888 193.781 29.6632 193.781 32.2329C193.781 34.6049 192.4 36.7793 188.455 36.7793H179.774V36.9769V36.7793ZM198.713 52.5928H201.08V48.2441H199.108C197.332 48.2441 195.754 47.4534 194.373 45.4767C194.373 45.4767 191.611 41.5233 191.414 40.7326C195.951 39.7443 198.713 36.7793 198.713 32.0352C198.713 27.2912 195.557 22.7448 188.257 22.7448H174.645V51.9998H179.577V41.3256H185.495C185.89 41.5233 186.087 42.1163 186.876 43.3023C187.468 44.4883 189.244 47.058 189.244 47.058C192.006 51.2091 194.176 52.5928 198.516 52.5928H198.713Z" fill="currentColor"/>
    <path d="M307.57 36.7793V27.4888H316.25C319.999 27.4888 321.577 29.6632 321.577 32.2329C321.577 34.6049 320.196 36.7793 316.25 36.7793H307.57V36.9769V36.7793ZM326.509 52.5928H328.876V48.2441H326.904C325.128 48.2441 323.55 47.4534 322.169 45.4767C322.169 45.4767 319.407 41.5233 319.21 40.7326C323.747 39.7443 326.509 36.7793 326.509 32.0352C326.509 27.2912 323.353 22.7448 316.053 22.7448H302.441V51.9998H307.373V41.3256H313.291C313.686 41.5233 313.883 42.1163 314.672 43.3023C315.264 44.4883 317.04 47.058 317.04 47.058C319.999 51.2091 321.972 52.5928 326.312 52.5928H326.509Z" fill="currentColor"/>
    <path d="M125.257 43.1045L109.278 22.9423H105.332V52.1973H110.461V32.0351L126.441 52.1973H130.387V22.9423H125.257V43.1045Z" fill="currentColor"/>
    <path d="M228.643 22.9423H204.772V27.4887H214.045V52.1973H219.371V27.4887H228.643V22.9423Z" fill="currentColor"/>
    <path d="M241.21 40.1395H256.203V52.1973H261.135V22.9423H256.203V35.3954H241.21V22.9423H236.278V52.1973H241.21V40.1395Z" fill="currentColor"/>
    <path d="M293.424 47.2556H276.655V39.7442H292.634V35.1978H276.655V27.6863H293.226V22.9423H271.723V52.1973H293.424V47.2556Z" fill="currentColor"/>
    <path d="M356.037 22.9423V43.1045L339.86 22.9423H336.112V52.1973H341.044V32.0351L357.221 51.9996V52.1973H360.969V22.9423H356.037Z" fill="currentColor"/>
    <path d="M152.422 48.2439C146.504 48.2439 141.967 43.4999 141.967 37.7675C141.967 31.8374 146.701 27.291 152.422 27.291C158.341 27.291 162.878 32.0351 162.878 37.7675C162.878 43.4999 158.143 48.2439 152.422 48.2439ZM152.422 22.3493C144.137 22.3493 137.232 29.2677 137.232 37.5698C137.232 45.8719 144.137 52.7903 152.422 52.7903C160.708 52.7903 167.613 45.8719 167.613 37.5698C167.613 29.2677 160.708 22.3493 152.422 22.3493Z" fill="currentColor"/>
    <path d="M111.438 134.611C113.604 134.611 114.983 133.427 114.983 131.453C114.983 129.48 113.604 128.099 111.438 128.099H107.696V134.611H111.438ZM107.696 136.979V144.675H105.135V125.928H112.029C115.574 125.928 117.74 128.296 117.74 131.453C117.74 134.611 115.377 136.979 112.029 136.979H107.696Z" fill="currentColor"/>
    <path d="M127.79 134.426C127.396 134.426 127.001 134.426 126.607 134.426C124.438 134.426 123.058 135.612 123.058 138.378V144.7H120.495V131.858H122.861V134.031C123.846 132.055 125.424 131.66 126.607 131.66C127.001 131.66 127.396 131.66 127.396 131.66V134.229L127.79 134.426Z" fill="currentColor"/>
    <path d="M139.016 136.994C139.016 135.216 137.833 133.636 135.467 133.636C133.298 133.636 132.115 135.216 131.918 136.994H138.819H139.016ZM141.58 141.143C140.791 143.316 138.819 145.094 135.862 145.094C132.51 145.094 129.355 142.526 129.355 138.18C129.355 134.228 132.312 131.463 135.467 131.463C139.411 131.463 141.58 134.228 141.58 138.18C141.58 138.575 141.58 138.772 141.58 138.97H131.918C131.918 141.341 133.693 142.921 135.862 142.921C137.833 142.921 139.016 141.736 139.411 140.353L141.58 141.143Z" fill="currentColor"/>
    <path d="M145.899 140.551C146.096 141.934 147.082 142.921 148.857 142.921C150.237 142.921 151.026 142.131 151.026 141.143C151.026 140.353 150.434 139.76 149.448 139.563L147.279 139.168C145.308 138.773 144.125 137.39 144.125 135.612C144.125 133.438 146.096 131.66 148.66 131.66C152.209 131.66 153.195 134.031 153.589 135.019L151.42 135.809C151.223 135.217 150.829 133.636 148.857 133.636C147.674 133.636 146.688 134.426 146.688 135.414C146.688 136.204 147.082 136.797 148.068 136.995L150.04 137.39C152.406 137.982 153.589 139.365 153.589 141.143C153.589 142.921 152.012 145.095 148.857 145.095C145.308 145.095 143.927 142.921 143.73 141.341L145.899 140.551Z" fill="currentColor"/>
    <path d="M165.403 136.994C165.403 135.216 164.22 133.636 161.853 133.636C159.684 133.636 158.501 135.216 158.304 136.994H165.205H165.403ZM167.966 141.143C167.177 143.316 165.205 145.094 162.248 145.094C158.896 145.094 155.741 142.526 155.741 138.18C155.741 134.228 158.699 131.463 161.853 131.463C165.797 131.463 167.966 134.228 167.966 138.18C167.966 138.575 167.966 138.772 167.966 138.97H158.304C158.304 141.341 160.079 142.921 162.248 142.921C164.417 142.921 165.403 141.736 165.797 140.353L167.966 141.143Z" fill="currentColor"/>
    <path d="M173.663 144.699H171.1V131.858H173.466V133.636C174.452 132.055 175.832 131.463 177.41 131.463C180.367 131.463 181.945 133.636 181.945 136.599V144.699H179.381V136.994C179.381 135.216 178.593 133.833 176.424 133.833C174.452 133.833 173.466 135.414 173.466 137.389V144.699H173.663Z" fill="currentColor"/>
    <path d="M189.43 131.865H192.197V134.043H189.43V140.775C189.43 141.963 189.825 142.557 191.209 142.557C191.604 142.557 191.999 142.557 192.197 142.359V144.537C191.999 144.537 191.406 144.735 190.418 144.735C188.244 144.735 186.861 143.349 186.861 141.171V134.043H184.49V131.865H185.28C186.664 131.865 187.256 131.073 187.256 129.885V127.707H189.628V131.667L189.43 131.865Z" fill="currentColor"/>
    <path d="M195.517 144.674V131.847H198.078V144.674H195.517ZM196.699 125.335C197.684 125.335 198.472 126.124 198.472 127.111C198.472 128.098 197.684 128.887 196.699 128.887C195.714 128.887 194.927 128.098 194.927 127.111C194.927 126.124 195.714 125.335 196.699 125.335Z" fill="currentColor"/>
    <path d="M204.578 144.699H202.015V131.858H204.381V133.636C205.367 132.055 206.747 131.463 208.324 131.463C211.282 131.463 212.859 133.636 212.859 136.599V144.699H210.296V136.994C210.296 135.216 209.507 133.833 207.339 133.833C205.367 133.833 204.381 135.414 204.381 137.389V144.699H204.578Z" fill="currentColor"/>
    <path d="M226.041 137.778C226.041 135.213 224.465 133.634 222.496 133.634C220.329 133.634 218.754 135.213 218.754 137.778C218.754 140.343 220.132 141.922 222.496 141.922C224.662 141.922 226.041 140.343 226.041 137.778ZM218.557 144.685C218.754 146.461 220.132 147.842 222.102 147.842C224.859 147.842 226.041 146.461 226.041 143.698V141.922C225.45 143.106 224.071 144.093 222.102 144.093C218.754 144.093 216.193 141.527 216.193 137.975C216.193 134.621 218.557 131.858 222.102 131.858C224.071 131.858 225.45 132.647 226.041 133.831V132.055H228.601V143.896C228.601 147.053 227.026 150.21 222.299 150.21C219.148 150.21 216.784 148.237 216.39 145.474L218.754 144.882L218.557 144.685Z" fill="currentColor"/>
    <path d="M248.883 130.861C248.686 129.479 247.504 127.703 244.944 127.703C242.777 127.703 241.398 129.085 241.398 130.861C241.398 132.242 242.186 133.229 243.762 133.623L246.519 134.215C249.67 135.005 251.443 136.781 251.443 139.544C251.443 142.504 248.883 145.069 244.944 145.069C240.414 145.069 238.247 142.109 237.853 139.544L240.414 138.754C240.611 140.925 241.989 142.701 244.944 142.701C247.504 142.701 248.883 141.32 248.883 139.741C248.883 138.36 247.898 137.175 246.125 136.781L243.368 136.189C240.611 135.597 238.838 133.821 238.838 131.058C238.838 128.098 241.595 125.532 244.944 125.532C249.276 125.532 250.852 128.098 251.246 130.269L248.883 131.058V130.861Z" fill="currentColor"/>
    <path d="M260.894 133.831C258.53 133.831 257.152 135.804 257.152 138.37C257.152 140.935 258.727 142.909 260.894 142.909C263.257 142.909 264.636 140.935 264.636 138.37C264.636 135.804 263.257 133.831 260.894 133.831ZM254.591 149.816V132.055H256.955V134.028C257.743 132.844 259.121 131.66 261.288 131.66C265.03 131.66 267.196 134.62 267.196 138.37C267.196 142.119 265.03 145.079 261.288 145.079C259.318 145.079 257.94 144.093 257.152 142.909V149.618H254.591V149.816Z" fill="currentColor"/>
    <path d="M279.809 138.18C279.809 135.216 278.034 133.636 275.865 133.636C273.696 133.636 271.922 135.216 271.922 138.18C271.922 141.143 273.696 142.724 275.865 142.724C278.034 142.724 279.809 141.143 279.809 138.18ZM282.372 138.18C282.372 142.131 279.611 144.897 275.865 144.897C272.119 144.897 269.358 141.933 269.358 138.18C269.358 134.228 272.119 131.463 275.865 131.463C279.611 131.463 282.372 134.426 282.372 138.18Z" fill="currentColor"/>
    <path d="M288.069 144.699H285.506V131.858H287.872V133.636C288.858 132.055 290.238 131.463 291.815 131.463C294.773 131.463 296.35 133.636 296.35 136.599V144.699H293.787V136.994C293.787 135.216 292.998 133.833 290.829 133.833C288.858 133.833 287.872 135.414 287.872 137.389V144.699H288.069Z" fill="currentColor"/>
    <path d="M301.458 140.551C301.655 141.934 302.641 142.921 304.415 142.921C305.795 142.921 306.584 142.131 306.584 141.143C306.584 140.353 305.993 139.76 305.007 139.563L302.838 139.168C300.866 138.773 299.683 137.39 299.683 135.612C299.683 133.438 301.655 131.66 304.218 131.66C307.767 131.66 308.753 134.031 309.147 135.019L306.978 135.809C306.781 135.217 306.387 133.636 304.415 133.636C303.232 133.636 302.246 134.426 302.246 135.414C302.246 136.204 302.641 136.797 303.626 136.995L305.795 137.39C308.161 137.982 309.345 139.365 309.345 141.143C309.345 142.921 307.767 145.095 304.612 145.095C301.063 145.095 299.683 142.921 299.486 141.341L301.655 140.551H301.458Z" fill="currentColor"/>
    <path d="M321.949 138.18C321.949 135.216 320.174 133.636 318.005 133.636C315.836 133.636 314.062 135.216 314.062 138.18C314.062 141.143 315.836 142.724 318.005 142.724C320.174 142.724 321.949 141.143 321.949 138.18ZM324.512 138.18C324.512 142.131 321.751 144.897 318.005 144.897C314.259 144.897 311.498 141.933 311.498 138.18C311.498 134.228 314.259 131.463 318.005 131.463C321.751 131.463 324.512 134.426 324.512 138.18Z" fill="currentColor"/>
    <path d="M334.743 134.426C334.349 134.426 333.955 134.426 333.56 134.426C331.391 134.426 330.011 135.612 330.011 138.378V144.7H327.448V131.858H329.814V134.031C330.8 132.055 332.377 131.66 333.56 131.66C333.955 131.66 334.349 131.66 334.349 131.66V134.229L334.743 134.426Z" fill="currentColor"/>
  </svg>
);

export default SvgNorthernTrust;
