import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { parse, stringify } from "utils/query-string";

export default function useUpdateQueryState() {
  const history = useHistory();

  const getUpdatedLocation = useCallback(
    function <T>(newQueryState: T, force?: boolean) {
      const { location } = history;
      const { search } = location;

      const prevQueryState = force ? {} : parse(search);
      const newSearch = stringify({ ...prevQueryState, ...newQueryState });

      return {
        ...location,
        search: newSearch,
      };
    },
    [history]
  );

  const push = useCallback(
    function push<T = any>(newQueryState: T, force?: boolean) {
      history.push(getUpdatedLocation(newQueryState, force));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const replace = useCallback(
    function replaceState<T = any>(newQueryState: T, force?: boolean) {
      history.replace(getUpdatedLocation(newQueryState, force));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { push, replace, getUpdatedLocation };
}
