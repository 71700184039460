import * as React from "react";

const SvgPlanArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 15 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 1l13 10.133L1 21" stroke="#000" />
  </svg>
);

export default SvgPlanArrow;
