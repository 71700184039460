import { css } from "@linaria/core";
import clsx from "clsx";
import { darken, lighten } from "polished";
import React from "react";
import { up } from "styles/utils/breakpoints";
import em from "styles/utils/em";

export const buttonCss = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;

  border: 1px solid black;

  font-size: 14px;
  letter-spacing: ${em("0.31px", "14px")};

  & {
    transition: all 150ms ease-in-out;
    transition-property: color, background-color, border;
  }

  & path,
  & circle,
  & rect {
    transition: all 100ms ease-in-out;
    transition-property: fill, stroke;
  }

  &.fullWidth {
    width: 100%;
  }

  &.noXborders {
    border-left: 0;
    border-right: 0;
  }

  .dark &,
  &.secondary,
  a.active > &,
  a&.active,
  &.pressed {
    background-color: #9E9F9E;
    color: #ffffff;
  }

  .dark &.secondary,
  .dark a.active > &,
  & {
    background-color: #ffffff;
    color: #000000;
  }

  &.uppercase {
    text-transform: uppercase;
  }



  ${up("lg")} {
    &:hover.secondary,
    &:hover.active,
    .dark &:hover {
      background-color: #ffffff;
      color: #000000;
    }

    &:hover,
    .dark &:hover.active,
    .dark &:hover.secondary {
      background-color: #000000;
      color: #ffffff;
    }
  }

  &:disabled,
  &.disabled {
    background-color: ${darken(0.2, "#ffffff")};
    border-color: ${lighten(0.5, "#000000")};
    color: #000000;
  }
`;

const Button = ({
  className,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return <button className={clsx(buttonCss, className)} {...props} />;
};

export default Button;
