import React, { useMemo } from "react";
import { styled } from "@linaria/react";
import { spacerMixin } from "components/BaseSpacer";
import { down, up } from "styles/utils/breakpoints";
import em from "styles/utils/em";
import responsive from "styles/utils/responsive";
import FacebookSvg from "components/icons/FacebookSvg";
import InstagramSvg from "components/icons/InstagramSvg";
import ArtsySvg from "components/icons/ArtsySvg";
import MailSvg from "components/icons/MailSvg";
import useConstants from "features/ServerConstants/hooks/useConstants";
import TwitterSvg from "components/icons/TwitterSvg";

type FooterProps = {
  className?: string;
};

const FooterRoot = styled.footer`
  z-index: 10;
  position: relative;
  width: 100%;
  color: #000;
  background-color: #fff;
  padding-block: 12px;
  border-top: solid 1px #000;
  /* padding: 12px 264px; */
  /* ${down("lg")} {
    &:not(.disableMargin) {
      margin-top: 40px;
    }
  } */
`;

const List = styled.ul`
  display: flex;
  align-items: center;
  /* ${spacerMixin} */
  /* border-top: solid 1px rgb(0, 0, 0); */
  /* padding-inline: 30px;     DOES NOT WORK IN SAFARI */
  padding: 0 30px;
  gap: 20px;

  ${up("lg")} {
    padding-top: 0;
    /* padding-inline: 120px; DOES NOT WORK IN SAFARI */
    padding: 0 120px;
    align-items: center;
    gap: 62px;
  }

  ${up("xl")} {
    /* padding-inline: 220px; DOES NOT WORK IN SAFARI */
    padding: 0 220px;
  }
`;

const Text = styled.p`
  flex-shrink: 0;
  font-size: 13px;
  line-height: 1.67;
  letter-spacing: ${em("0.26px", "12px")};
  color: #000;
  ${up("lg")} {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.280159px;
  }
`;

const Link = styled.a`
  text-decoration: none;
`;

const ListItem = styled.li`
  flex-shrink: 0;
  &:first-child {
    display: initial;
  }

  &:last-child {
    margin-left: auto;
  }

  ${up("lg")} {
    display: initial;
  }
`;

const ListSocial = styled.ul`
  display: flex;
  ${Link} {
    ${responsive("margin-left", {
      base: "7px",
    })}
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #000;
  svg {
    fill: #fff;
  }
`;

const year = new Date().getFullYear();
const Footer = ({ className }: FooterProps) => {
  const { data: constats } = useConstants();
  const FooterLinks = useMemo(() => {
    const res = constats?.Constant.filter(
      (item) => item.name === "Footer Links"
    )[0];
    if (!res) return;
    return JSON.parse(res.value);
  }, [constats]);

  return (
    <FooterRoot className={className}>
      <List className="list">
        <ListItem>
          <Text>
            DEMO VIEWING ROOM <br />7-10 APRIL {year}
          </Text>
        </ListItem>
        {FooterLinks?.links?.map((item: any) => (
          <ListItem>
            <Link href={item?.link} target="_blank" rel="noopener noreferrer">
              <Text>{item?.name}</Text>
            </Link>
          </ListItem>
        ))}

        <ListItem>
          <ListSocial>
            <Link
              href={"https://www.facebook.com/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconContainer>
                <FacebookSvg height="18" />
              </IconContainer>
            </Link>
            <Link
              href={"https://www.instagram.com/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconContainer>
                <InstagramSvg height="18" />
              </IconContainer>
            </Link>
            <Link
              href={"https://twitter.com/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconContainer>
                <TwitterSvg height="18" />
              </IconContainer>
            </Link>
            {/*<Link*/}
            {/*  href={*/}
            {/*    "https://expochicago.us4.list-manage.com/subscribe?u=feab88d1702b05fff44372e88&id=dd28d32606"*/}
            {/*  }*/}
            {/*  target="_blank"*/}
            {/*  rel="noopener noreferrer"*/}
            {/*>*/}
            {/*  <IconContainer>*/}
            {/*    <MailSvg height="18" />*/}
            {/*  </IconContainer>*/}
            {/*</Link>*/}
            {/*<Link*/}
            {/*  href={"https://www.artsy.net/fair-organizer/expo-chicago "}*/}
            {/*  target="_blank"*/}
            {/*  rel="noopener noreferrer"*/}
            {/*>*/}
            {/*  <IconContainer>*/}
            {/*    <ArtsySvg height="18" />*/}
            {/*  </IconContainer>*/}
            {/*</Link>*/}
          </ListSocial>
        </ListItem>
      </List>
    </FooterRoot>
  );
};

export default Footer;
