import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type StateType = {
  active: boolean;
  viewingRoomId: number | null;
}

const initialState: StateType = {
  active: false,
  viewingRoomId: null
};

const { reducer, actions } = createSlice({
  name: "signTheBook",
  initialState,
  reducers: {
    setActive(state, action: PayloadAction<StateType['active']>) {
      state.active = action.payload;
    },
    setViewingRoomId(state, action: PayloadAction<StateType['viewingRoomId']>) {
      state.viewingRoomId = action.payload;
    },
  },
});

export const { setActive, setViewingRoomId } = actions;

export default reducer;
