import { getStreamChatKey } from "config/stream-chat";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import { StreamChat } from "stream-chat";
import sleep from "utils/sleep";
import {
  ChatApi,
  ChatCredetials,
  ChatMode
} from "../interfaces/chat.interfaces";

const ChatContext = React.createContext<ChatApi | null>(null);

const ChatProvider = ({ children }: PropsWithChildren<{}>) => {
  const [client, setClient] = useState<StreamChat>();
  const [mode, setMode] = useState<ChatMode>("default");
  const [filters, setFilters] = useState<any>();
  const [latestChannelId, setlatestChannelId] = useState<string>();

  const close = useCallback(async () => {
    setFilters(undefined);
    await sleep(100);
    await client?.disconnectUser();
    setClient(undefined);
    setMode("default");
    setlatestChannelId(undefined);
  }, [client]);

  const open = useCallback(
    async (credentials: ChatCredetials) => {
      let streamClient = client;
      if (!streamClient) {
        streamClient = new StreamChat(getStreamChatKey);
        setClient(streamClient);
        setFilters({ members: { $in: [credentials.id] } });
        streamClient.connectUser(
          {
            id: credentials.id,
          },
          credentials.token
        );
      }

      setMode("default");
      const channelId = credentials.channelId.split(":")[1];
      const channel = streamClient.channel("messaging", channelId);
      await channel.watch();

      setlatestChannelId(channelId);
      (window as any).client = streamClient;
      (window as any).channel = streamClient;
    },
    [client]
  );

  const vlaue: ChatApi = useMemo(
    () => ({
      mode,
      close,
      open,
      client,
      setMode,
      filters,
      active: !!filters && !!client,
      latestChannelId,
    }),
    [client, close, filters, latestChannelId, mode, open]
  );

  return <ChatContext.Provider value={vlaue}>{children}</ChatContext.Provider>;
};

export const useChatClient = () => useContext(ChatContext) as ChatApi;

export default ChatProvider;
