import { createLocation, Location, LocationDescriptorObject } from "history";
import { useMemo } from "react";
import { LinkProps } from "react-router-dom";
import { GoBackState } from "../components/NavigationLink";

export function resolveToLocation<T>(
  to: LinkProps["to"],
  currentLocation: Location<T>
) {
  return typeof to === "function" ? to(currentLocation) : to;
}

export function normalizeToLocation<T>(
  to: LinkProps["to"],
  currentLocation: Location<T>
) {
  return typeof to === "string"
    ? createLocation(to, null, undefined, currentLocation)
    : to;
}

export default function useGoBackTo(
  to: LinkProps["to"],
  location: Location<GoBackState>
): LinkProps["to"] {
  return useMemo(() => {
    const newLocation = normalizeToLocation(
      resolveToLocation(to, location),
      location
    ) as LocationDescriptorObject<GoBackState>;

    const prevState = newLocation?.state ?? {};
    return { ...newLocation, state: { ...prevState, goBack: true } };
  }, [location, to]);
}
