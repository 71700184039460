import * as React from "react";

const SvgHeartFilled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="filled"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={19}
    viewBox="0 0 22 19"
    {...props}
  >
    <path d="M15.974 0c-1.76-.01-3.432.831-4.565 2.295C10.282.821 8.605-.022 6.843 0 3.54 0 .863 2.914.863 6.508c0 6.158 9.878 12.172 10.279 12.404a.449.449 0 00.534 0c.4-.232 10.278-6.159 10.278-12.404 0-3.594-2.677-6.508-5.98-6.508z" />
  </svg>
);

export default SvgHeartFilled;
