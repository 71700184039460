import usePublicConstants from "features/ServerConstants/hooks/usePublicConstants";
import React, { useMemo } from "react";
import { up } from "styles/utils/breakpoints";
import useBreakpoint from "styles/utils/useBreakpoint";

const ClosedBanner = React.lazy(
  () => import("features/Banner/ClosedBanner/ClosedBanner")
);

const ClosedBannerMobile = React.lazy(
  () => import("features/Banner/ClosedBannerMobile/ClosedBannerMobile")
);

const initialData = {
  text: "",
  link: {
    name: "",
    path: "",
  },
};

const ClosedView = () => {
  const {data} = usePublicConstants();

  const content = useMemo(() => {
    const values = data?.Constant.find((i) => i.name === "Close Content")?.value;

    if (!values) {
      return initialData;
    }

    const json = JSON.parse(`${values}`);
    if (typeof json !== "object") {
      return initialData;
    }

    return json;
  }, [data]);

  const upLg = useBreakpoint(up("lg"));
  return upLg ? <ClosedBanner link={content.link} text={content.text}/> : <ClosedBannerMobile link={content.link} text={content.text}/>;
};

export default ClosedView;
