import React from "react";
import useBreakpoint from "styles/utils/useBreakpoint";
import { up } from "styles/utils/breakpoints";

const ViewDesktop = React.lazy(() => import("./desktop/WelcomePage"));
const ViewMobile = React.lazy(() => import("./mobile/WelcomePage"));

const WelcomePageView = () => {
  const upLg = useBreakpoint(up("lg"));

  return upLg ? <ViewDesktop /> : <ViewMobile />;
};

export default WelcomePageView;
