import ky from "ky";

type CreateApiType = {
  getToken: () => string | null | undefined;
  getPreviewToken: () => string | null | undefined;
};

export const createApi = ({ getToken, getPreviewToken }: CreateApiType) =>
  ky.create({
    retry: 0,
    hooks: {
      beforeRequest: [
        (request) => {
          const token = getToken();
          const previewToken = getPreviewToken();
          if (previewToken) {
            request.headers.append("Preview-Token", previewToken);
          } else if (token) {
            request.headers.append("AUTHORIZATION", `Bearer ${token}`);
          }

          return request;
        },
      ],
    },
  });

export type FetchApi = typeof ky;
