export const meRoute = {
  path: `/me`,
  link: `/me`,
};

export const profileRoute = {
  path: `${meRoute.path}/profile`,
  link: `${meRoute.link}/profile`,
};

export const personalDataRoute = {
  path: `${meRoute.path}/overview`,
  link: `${meRoute.link}/overview`,
};

export const collectorProfileRoute = {
  path: `${profileRoute.path}/collector-profile`,
  link: `${profileRoute.path}/collector-profile`,
};

export const favoritesRoute = {
  path: `${meRoute.path}/favorite`,
  link: `${meRoute.link}/favorite`,
};

export const savedViewingRoomsRoute = {
  path: `${favoritesRoute.path}/viewing-rooms`,
  link: `${favoritesRoute.link}/viewing-rooms`,
};

export const savedArtworksRoute = {
  path: `${favoritesRoute.path}/artworks`,
  link: `${favoritesRoute.path}/artworks`,
};
