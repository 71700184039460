import { css } from "@linaria/core";
import clsx from "clsx";
import React from "react";
import { up } from "styles/utils/breakpoints";

export const headerItemTextCss = css`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: 'Circular Pro';
  
  font-size: 14px;
  letter-spacing: 0.355556px;
  line-height: 20px;
  font-weight: 400;
  text-decoration: none;
  text-transform: initial;
  text-align: center;
  cursor: default;

  

  transition: opacity 100ms ease-in-out;

  & {
    text-decoration: none;
  }

  &::after {
    content: attr(data-text);
    content: attr(data-text) / "";
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-weight: 400;

    @media speech {
      display: none;
    }
  }

  ${up('xl')} {
    font-size: 16px;
  }
`;

const navigationItemText = css`
  display: flex;
  flex: 1;
  color: #000;

  .active > &,
  &.active {
    font-weight: 400;
    opacity: 1;
  }

  .expanded .main:not(.active) > &,
  &.inline:not(.active) {
    opacity: 0.7;
    font-weight: 400;
  }

  & {
    opacity: 0.7;
  }

  &:hover {
    opacity: 1;
  }
`;

export const navigationItemTextCss = clsx(
  navigationItemText,
  headerItemTextCss
);

export default function NavigationItemText({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  return <div className={clsx(className, navigationItemTextCss)} {...props} />;
}
