import { styled } from "@linaria/react";
import React, { useCallback } from "react";
import { SnackbarComponentProps } from "./snackbar.interfaces";

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  width: 100%;
  padding: 14px;

  font-size: 14px;
  border-top: 1px solid black;
  /* border-bottom: 1px solid black; */
  background-color: #ffffff;

  text-transform: uppercase;

  transition: opacity 300ms ease-in-out;
`;

const Snackbar = ({ message, onClose, className }: SnackbarComponentProps) => {
  const handleClose = useCallback(() => {
    onClose(message.key);
  }, [message.key, onClose]);

  return (
    <Root className={className} onClick={handleClose}>
      {message.text}
    </Root>
  );
};

export default Snackbar;
