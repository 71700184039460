import { sponsorsSorter } from "features/ViewingRoom/hooks/useSponsors";
import { api } from "index";
import { useMemo } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { normalizeDataWthId } from "utils/normalizeDataWithId";
import sortByKey from "utils/sortByKey";
import sortByPriority from "utils/sortByPriority";
import { SERVER_SECURE_CONSTANTS_CACHE_KEY } from "../api.server-constants";
import { ConstantsType, SponsorDescription } from "../store/serverConstants";

const fetchServerConstatnsRequest = () => {
  return api.get(`/api/v1/user/constants`).json<ConstantsType>();
};

export default function useConstants(config?: UseQueryOptions<ConstantsType>) {
  const { data, ...rest } = useQuery(
    SERVER_SECURE_CONSTANTS_CACHE_KEY,
    fetchServerConstatnsRequest,
    {
      staleTime: Infinity,
      ...config,
    }
  );

  const normalizedData = useMemo(() => {
    if (!data) {
      return data;
    }
    data.Section = data.Section.sort(sortByPriority);
    data.Fair = data.Fair.sort(sortByPriority);
    data.MainScreenSection = data.MainScreenSection.sort(sortByKey("sortPriority"))
    data.NavigationItem = data.NavigationItem.sort(sortByKey("sortPriority")).map((item) => {
      item.items = item.items.sort(sortByKey("parentSortPriority"));
      return item;
    });
    data.Year = data.Year.sort(sortByKey("sortPriority"));
    return {
      ...data,
      Partners: data.Partners.sort(sponsorsSorter),
      maps: {
        priceRange: normalizeDataWthId(data.PriceRange).itemsMap,
        currency: normalizeDataWthId(data.Currency).itemsMap,
        artworkCategory: normalizeDataWthId(data.ArtworkCategory).itemsMap,
        fair: normalizeDataWthId(data.Fair).itemsMap,
        section: normalizeDataWthId(data.Section).itemsMap,
        medium: normalizeDataWthId(data.Medium).itemsMap,
        centuryRange: normalizeDataWthId(data.CenturyRange).itemsMap,
        constant: normalizeDataWthId(data.Constant).itemsMap,
        homepageContent: normalizeDataWthId(data.HomepageContent).itemsMap,
        mainScreenDescription: data.MainScreenDescription.reduce(
          (acc, curr) => {
            acc[curr.viewingRoomId] = curr;
            return acc;
          },
          {} as Record<number, SponsorDescription>
        ),
      },
    };
  }, [data]);

  return { data: normalizedData, ...rest };
}
