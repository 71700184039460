import CustomEvent from "./CustomEvent";
import { WSAction, WSHandler } from "./interfaces/ws.interfaces";

export default class WsClient {
  socket?: WebSocket;
  protected handlers: Record<string, WSHandler<any>[]>;
  openEvent = new CustomEvent<void>();
  closeEvent = new CustomEvent<void>();
  refetchTime: any;
  url?: string;

  constructor() {
    this.handlers = {};

    this.addHandlers();
    this.onMessage = this.onMessage.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.refetchTime = null;
  }

  protected connect(url: string) {
    if (this.refetchTime) {
      clearTimeout(this.refetchTime);
      this.refetchTime = null;
    }

    this.url = url;
    this.socket = new WebSocket(url);
    this.addHandlers();
  }

  protected addHandlers() {
    if (this.socket) {
      this.socket.addEventListener("message", this.onMessage);
      this.socket.addEventListener("close", this.handleClose);
      this.socket.addEventListener("open", this.handleOpen);
    }
  }

  addMessageHandler<T extends WSAction>(action: string, handler: WSHandler<T>) {
    const handlers = this.handlers[action] || [];
    handlers.push(handler);
    this.handlers[action] = handlers;
  }

  removeMessageHandler<T extends WSAction>(
    action: string,
    handler: WSHandler<T>
  ) {
    const handlers = this.handlers[action];
    if (handlers && handlers.length) {
      this.handlers[action] = handlers.filter((i) => i !== handler);
    }
  }

  protected onMessage(ev: MessageEvent) {
    try {
      const json = JSON.parse(ev.data) as WSAction;
      if (!json.action) {
        return;
      }
      if (json.action === "ping") {
        this.sendMessage("pong", {});
      }
      const handlers = this.handlers[json.action] || [];
      handlers.forEach((handler) => handler(json));
    } catch (e) {
      console.log("WS Parse message error");
      console.log(e);
    }
  }

  sendMessage<T extends Object>(action: string, body: T) {
    if (this.socket && this.socket.readyState === this.socket.OPEN) {
      const str = JSON.stringify({ action, ...body });
      this.socket.send(str);
    } else {
      console.log("can't send message: ws closed");
    }
  }

  protected handleOpen() {
    console.log("ws open");

    this.openEvent.dispatchEvent();
  }

  protected handleClose() {
    console.log("ws close");
    this.closeEvent.dispatchEvent();

    this.refetchTime = setTimeout(() => {
      if (!this.url) return;
      this.connect(this.url);
    }, 60 * 1000);
  }

  close() {
    if (this.socket) {
      this.socket.removeEventListener("message", this.onMessage);
      this.socket.removeEventListener("close", this.handleClose);
      this.socket.removeEventListener("open", this.handleOpen);
      this.socket.close();
      this.closeEvent.dispatchEvent();
    }
  }
}
