import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  searchString: string;
  active: boolean;
  showResults: boolean;
} = {
  active: false,
  showResults: false,
  searchString: "",
};

const { actions, reducer } = createSlice({
  name: "searchOverlay",
  initialState,

  reducers: {
    setSearchString(state, action: PayloadAction<{ searchString: string }>) {
      state.searchString = action.payload.searchString;
    },

    clearSearchString(state) {
      state.searchString = "";
    },

    setSearchActive(state, action: PayloadAction<{ active: boolean }>) {
      state.active = action.payload.active;
      state.showResults = false;
    },
    setShowResults(state, action: PayloadAction<{ show: boolean }>) {
      state.showResults = action.payload.show;
    },
  },
});

export const {
  setSearchString,
  clearSearchString,
  setSearchActive,

  setShowResults,
} = actions;

export default reducer;
