import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChatCredetials } from "../interfaces/chat.interfaces";

const initialState: {
  activeChat?: ChatCredetials;
  title?: string;
} = {};

const slice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setActiveChat(
      state,
      action: PayloadAction<{ credentials?: ChatCredetials; title?: string }>
    ) {
      state.activeChat = action.payload.credentials;
      state.title = action.payload.title;
    },
  },
});

export default slice.reducer;

export const { setActiveChat } = slice.actions;
