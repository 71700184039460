export type NormalizerResponse<T, K = number> = {
  ids: K[];
  itemsMap: Record<string, T>;
};

export const normalizeDataWthId = <T extends { id: number }>(
  items: T[]
): NormalizerResponse<T> => {
  const ids: number[] = [];
  const itemsMap: Record<string, T> = {};

  items.forEach((item) => {
    ids.push(item.id);
    itemsMap[item.id] = item;
  });
  return { ids, itemsMap };
};
