import React, { useCallback } from "react";
import { NavLink, NavLinkProps, useHistory } from "react-router-dom";
import { INITIAL_SCROLL_KEY, saveScrollPosition } from "../ScrollStore";

type ScrollLinkProps = NavLinkProps & {
  saveScroll?: boolean;
};

const ScrollLink = ({ saveScroll, onClick, ...rest }: ScrollLinkProps) => {
  const history = useHistory();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      const scrollKey = history.location.key || INITIAL_SCROLL_KEY;
      if (saveScroll && scrollKey) {
        saveScrollPosition(scrollKey);
      }
      onClick && onClick(e);
    },
    [history, onClick, saveScroll]
  );

  return <NavLink onClick={handleClick} {...rest} />;
};

export default ScrollLink;
