import { ContextNavigationDesktop } from "features/ContextNavigation/ContextNavigation/ContextNavigationDesktop";
import { ContextNavigationMobile } from "features/ContextNavigation/ContextNavigation/ContextNavigationMobile";
import React from "react";
import { up } from "styles/utils/breakpoints";
import useBreakpoint from "styles/utils/useBreakpoint";
import { faqRoute } from "../faq.routes";

type Props = {
  className?: string;
};

const FaqContextNavigation = ({ className }: Props) => {
  const upLg = useBreakpoint(up("lg"));

  return (
    <>
    { upLg && (
      <ContextNavigationDesktop
        className={className}
        prevLink={null}
        nextLink={null}
        meta={[
          { name: 'Home', link: () => '/' },
          { name: "Help", link: faqRoute.path }
        ]}
        type="section"
      />
    )}
    
    { !upLg &&(
      <ContextNavigationMobile
        className={className}
        prevLink={null}
        nextLink={null}
        title={'Faq'}
        type="section"
      />
    )}
    </>
  );
};

export default FaqContextNavigation;
