import * as React from "react";

const SvgArtworkSlideArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={20}
    viewBox="0 0 13 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 19L1 9.88 12 1" />
  </svg>
);

export default SvgArtworkSlideArrow;
