import * as React from "react";

const SvgBookmarkFilled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={19}
    className="filled"
    viewBox="0 0 16 19"
    {...props}
  >
    <path d="M14.752 0H1.523c-.313 0-.57.242-.57.537v17.456c0 .377.221.72.58.894.155.073.324.111.488.111a1.1 1.1 0 00.622-.188l5.494-3.709 5.5 3.709c.329.222.75.251 1.11.077a.997.997 0 00.58-.894V.537C15.322.242 15.065 0 14.752 0z" />
  </svg>
);

export default SvgBookmarkFilled;
