import useArtwork from "features/Artwork/hooks/useArtwork";
import useViewingRoom from "features/ViewingRoom/hooks/useViewingRoom";
import usePrevious from "hooks/usePrevious";
import { useEffect, useState } from "react";
import { useAnalyticsClient } from "../AnalyticsClientProvider";

export default function useAnalytics() {
  const { client, connected } = useAnalyticsClient();

  const [latestShow, setLatestShow] = useState<number | void>();
  const [latestArt, setLatestArt] = useState<number | void>();

  const { data: show } = useViewingRoom();
  const { data: art } = useArtwork();

  const previousShow = usePrevious(show);
  const previousArt = usePrevious(art);

  useEffect(() => {
    if (!client || !connected) {
      setLatestShow();
      setLatestArt();
      return;
    }

    if (previousShow && previousShow.id !== show?.id) {
      client.leftShow(previousShow.id.toString());
    }

    if (show) {
      if (latestShow !== show.id) {
        client.enteredShow(show.id.toString());
        setLatestShow(show.id);
      }
    } else {
      setLatestShow();
    }

    if (previousArt && previousArt.id !== art?.id) {
      client.leftArt(previousArt.id.toString());
    }

    if (art) {
      if (latestArt !== art.id) {
        client.enteredArt(art.id.toString());
        setLatestArt(art.id);
      }
    } else {
      setLatestArt();
    }
    

    // if (art) {
    //   if (!latestArt) {
    //     client.enteredArt(art.id.toString());
    //     setLatestArt(true);
    //   }
    // } else {
    //   if (latestArt && previousArt) {
    //     client.leftArt(previousArt.id.toString());
    //     setLatestArt(false);
    //   }
    // }
  }, [
    art,
    client,
    connected,
    latestArt,
    latestShow,
    previousArt,
    previousShow,
    show,
  ]);
}
