import { meRoute } from "features/Profile/profile.routes";

export const planYourVisitRoute = {
  path: meRoute.path,
  link: meRoute.link,
};

export const allGalleries = {
  path: `${meRoute.path}/all-galleries`,
  link: `${meRoute.link}/all-galleries`,
};

export const savedGalleries = {
  path: `${meRoute.path}/saved-galleries`,
  link: `${meRoute.link}/saved-galleries`,
};

export const savedArtworks = {
  path: `${meRoute.path}/saved-artworks`,
  link: `${meRoute.link}/saved-artworks`,
};
