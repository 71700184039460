import { styled } from "@linaria/react";

interface SpacerProps {
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
}

const Spacer = styled.div<SpacerProps>`
  margin-top: ${(props) => (props.mt ? props.mt : 0)}px;
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)}px;
  margin-left: ${(props) => (props.ml ? props.ml : 0)}px;
  margin-right: ${(props) => (props.mr ? props.mr : 0)}px;
`;

export default Spacer;
