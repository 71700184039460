import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Breakpoint = "xs" | "s" | "m" | "l" | null;
export const breakpoints: Breakpoint[] = [null, "xs", "s", "m", "l"];

const initialState: {
  breakpoint: Breakpoint;
} = {
  breakpoint: null,
};
const { reducer, actions } = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setBreakpoint(state, action: PayloadAction<{ breakpoint: Breakpoint }>) {
      state.breakpoint = action.payload.breakpoint;
    },
  },
});

export const { setBreakpoint } = actions;
export default reducer;
