import ReactQueryWrapper from "features/api/ReactQueryWrapper";
import LayoutObserver from "features/LayoutUtils/LayoutObserver";
import ScrollController from "features/ScrollController/ScrollController";
import { SnackbarProvider } from "features/Snackbar/SnackbarProvider";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { isMobile } from "utils/platform";
import store from "../../store";
import RootRouter from "./RootRouter";

const App = () => {
  // const nav = document.querySelector("html");

  // useEffect(() => {
  //   if (!nav || !isMobile) return;
  //   nav.addEventListener("click", () => {
  //     if (nav.requestFullscreen) {
  //       nav.requestFullscreen().then(() => {
  //         window.screen.orientation.lock("portrait");
  //       });
  //     }
  //   });
  // }, [nav]);

  return (
    <Provider store={store}>
      <ReactQueryWrapper>
        <SnackbarProvider>
          <BrowserRouter>
            <LayoutObserver />
            <ScrollController />
            <RootRouter />
          </BrowserRouter>
        </SnackbarProvider>
      </ReactQueryWrapper>
    </Provider>
  );
};

export default App;
