import usePublicConstants from "features/ServerConstants/hooks/usePublicConstants";
import { useEffect, useMemo, useState } from "react";
import { clearLongTimeout, setLongTimeout } from "utils/setLongTimeout";

let timer: number;

function isCloseTime(date: number) {
  const now = Date.now();
  return now >= date;
}

export default function useCloseLogin() {
  const { data, isFetched } = usePublicConstants();
  const [closed, setClosed] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const closeTime = useMemo(() => {
    const time = data?.Constant.find(
      (item) => item.name === "Waiting Room Access Time"
    )?.value;
    return time ? new Date(time).getTime() : Date.now();
  }, [data]);

  useEffect(() => {
    if (!closeTime || !isFetched) return;
    
    setClosed(isCloseTime(closeTime));
    setIsLoading(false)
    if (!closed) {
      const toClosing = closeTime - Date.now();
      setLongTimeout(() => setClosed(true), toClosing + 100);
    }
    return () => {
      if (timer) {
        clearLongTimeout(timer);
      }
    };
  }, [closeTime, setClosed, closed, setIsLoading, setIsLoading]);

  return {
    isLoading,
    closed,
  };
}
