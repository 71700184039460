import { css } from "@linaria/core";

export const fadeCss = css`
  :global() {
    .fade-in-enter {
      opacity: 0;
    }
    .fade-in-enter-active {
      opacity: 1;
    }
    .fade-in-enter-done {
      opacity: 1;
    }

    .fade-in-exit-active {
      opacity: 0;
    }
    .fade-in-exit {
      opacity: 0;
    }
    .fade-in-exit-done {
      opacity: 0;
    }
  }
`;

export const fadeClass = "fade-in";
