import { useCallback } from "react";

export default function useHandleKey(key: string, callback?: () => any) {
  return useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === key && callback) {
        callback();
      }
    },
    [callback, key]
  );
}
