import { Action, combineReducers } from "@reduxjs/toolkit";
import contextNavigation from "features/ContextNavigation/store/contextNavigationSlice";
import layout from "features/LayoutUtils/store/layoutUtilsSlice";
import mobileMenu from "features/MobileMenu/store/mobileMenuStore";
import searchOverlay from "../components/SearchOverlay/store/searchOverlaySlice";
import auth, { logOutType } from "../features/Auth/store/authSlice";
import chat from "../features/Chat/store/chatComponentSlice";
import signIn from "../features/Login/store/signInSlice";
import scrollController from "../features/ScrollController/store/scrollControllerSlice";
import search from "../features/Search/store/searchSlice";
import signTheBook from "../features/SignTheBook/store/signTheBookStore";
import main from "./mainSlice";

const appReducer = combineReducers({
  main,
  auth,
  signIn,
  contextNavigation,
  search,
  layout,
  scrollController,
  signTheBook,
  searchOverlay,
  chat,
  mobileMenu,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState | undefined, action: Action<string>) => {
  if (action.type === logOutType) {
    state = { layout: state?.layout } as any;
  }
  return appReducer(state, action);
};

export default rootReducer;
