import { getRandomInt } from "./getRandomInt";

const MAX_INT = 2147483647;

const LONG_TERM_CHECK_INTERVAL = 1000 * 60;

interface TimerConfig {
  timeout?: number;
  interval?: number;
}

const timersPool: Partial<Record<number, TimerConfig>> = {};

function getTimerId(): number {
  const id = Date.now() + getRandomInt(100);
  if (!!timersPool[id]) {
    return getTimerId();
  }
  return id;
}

export function setLongTimeout(callback: () => any, timeout: number) {
  const timerId = getTimerId();
  const timerConfig: TimerConfig = (timersPool[timerId] = {});

  function setPlatformTimeout() {
    timerConfig.timeout = window.setTimeout(callback, timeout);
  }
  if (timeout <= MAX_INT) {
    setPlatformTimeout();
  } else {
    const currentInterval = window.setInterval(() => {
      if (timeout <= MAX_INT) {
        setPlatformTimeout();
        clearInterval(currentInterval);
        timerConfig.interval = undefined;
      }
    }, LONG_TERM_CHECK_INTERVAL);
    timerConfig.interval = currentInterval;
  }
  return timerId;
}

export function clearLongTimeout(id: number) {
  const config = timersPool[id];
  if (!config) {
    return;
  }
  if (config.interval !== undefined) {
    clearInterval(config.interval);
  }
  if (config.timeout !== undefined) {
    clearTimeout(config.timeout);
  }
}
