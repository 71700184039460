import { css } from "@linaria/core";
import clsx from "clsx";
import { svgCss } from "components/Svg";
import React from "react";
import { up } from "styles/utils/breakpoints";
import fluid from "styles/utils/fluid";

const _headerMobileActionCss = css`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 0 10px;

  .cross {
    height: 23px;
    ${up('lg')} {
      height: 19px;
    }
  }

  &.menu {
    ${fluid("font-size", {
      base: "17px",
      xs: "19px",
    })}
  }
  &.search {
    ${fluid("font-size", {
      base: "18px",
      xs: "20px",
    })}
  }
  &.cross {
    ${fluid("font-size", {
      base: "15px",
      xs: "17px",
    })}
  }

  &:active {
    opacity: 0.7;
  }

  &.desktop {
    padding: 0;
    font-size: 23px;
  }
  &.desktop.cross {
    font-size: 19px;
  }
  &.desktop.menu {
    font-size: 17px;
  }
`;

export const headerActionCss = clsx(svgCss, _headerMobileActionCss);
export const EmptyHeaderMobileAction = () => null;
const HeaderAction = ({
  className,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return <button className={clsx(headerActionCss, className)} {...props} />;
};

export default HeaderAction;
