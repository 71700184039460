import { publicRoute } from "features/Login/login.routes";
import NextRedirect from "features/Next/NextRedirect";
import React from "react";
import { up } from "styles/utils/breakpoints";
import useBreakpoint from "styles/utils/useBreakpoint";

const SharedArtworkViewMobile = React.lazy(
  () => import("./SharedArtworkViewMobile")
);

const PublicArtworkView = () => {
  const upLg = useBreakpoint(up("lg"));

  if (upLg) {
    return <NextRedirect to={publicRoute.link} />;
  }
  return <SharedArtworkViewMobile />;
};

export default PublicArtworkView;
