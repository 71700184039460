export interface IToketStorage {
  getToken(): string | null;
  setToken(token: string): void;
  invalidateToken(): void;
}

const storage = localStorage;

const tokenStorage: IToketStorage = {
  getToken() {
    return storage.getItem("token");
  },
  setToken(token) {
    storage.setItem("token", token);
  },
  invalidateToken() {
    storage.removeItem("token");
  },
};

export default tokenStorage;
