import { styled } from "@linaria/react";
import FinalFormCheckbox from "components/Checkbox/FinalFormCheckbox/FinalFormCheckbox";
import { secondaryFont } from "styles";
import { up } from "styles/utils/breakpoints";
import em from "styles/utils/em";

export const FilterCheckbox = styled(FinalFormCheckbox)`
  margin-right: 10px;
  color: #9e9f9e;
  input {
    background: #9e9f9e;
  }
`;

export const FilterCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  color: #9e9f9e;
  font-size: 14px;
  font-family: ${secondaryFont};
  letter-spacing: ${em("1.3px", "13px")};
  text-transform: capitalize;
  ${up("lg")} {
    letter-spacing: 0.722222px;
  }

  &:hover {
    color: #0c194b !important;
    transition: 0.5s;

    * {
      border-color: #0c194b !important;
      transition: 0.5s;
    }
    *:checked,
    *::after {
      background-color: #0c194b !important;
      transition: 0.5s;
    }
  }
`;
