export const searchDebounceDelay = 300;
export const checkShowExhibitionDate = false;
export const selfOrigin = "/";

export type BackgroundConfig = {
  width: number;
  height: number;
  rationPxCm: number;
  wallHeightPx: number;
  backgroundPositionX: number | null;
  topPadding: number;
  backgroundImage: string;
};

const backgroundConfigBase = {
  width: 5699,
  height: 3000,
};

const baseBackgroundConfigNear = {
  ...backgroundConfigBase,
  wallHeightPx: 2070,
  topPadding: 0,
};

export const backgroundConfigFar: Omit<BackgroundConfig, "backgroundImage"> = {
  ...backgroundConfigBase,
  wallHeightPx: 2700,
  topPadding: -100,
  backgroundPositionX: -170,
  rationPxCm: 6,
};

export const backgroundConfigNear: Omit<BackgroundConfig, "backgroundImage"> = {
  ...baseBackgroundConfigNear,
  rationPxCm: 9.4,
  wallHeightPx: 2700,
  topPadding: -100,
  backgroundPositionX: -270,
};

export const wsAnalyticsHost =
  process.env.NODE_ENV !== "production"
    ? "develop.artnav.tk"
    : window.location.host;

export const paginationLimit = 20;
export const jwPlayerScript = "https://cdn.jwplayer.com/libraries/yKWvOSbH.js";

// Dev - 2
// Staging - 2
// Production - 10
export const FMFairId = 2

export const FLFairId = 1;

export const WedVipAccessId = 1;
export const ThurVipAccessId = 2;
export const PublicUserAccessId = 3;

export const editAccountInfoLink = "/";
export const vipProgrammingLink =
  "/";

export const wedVipProgrammeLink =
  "/";

export const friezeWeekLink = "/";

export const pdfViewrLink = "/pdf-viewer.html";
export const welcomeFriezeLink =
  "/";

export const partnersSectionId = 10;
export const iosAppLink =
  "/";
export const androidAppLink =
  "/";

export const getAppBannerPath = "/";

export const partnersLink = "/";

export const dbLink = "/";
