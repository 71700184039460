import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store";
import previewRoomStorage from "utils/previewRoomId";
import previewTokenStorage from "utils/previewTokenStorage";
import { clearLongTimeout, setLongTimeout } from "utils/setLongTimeout";
import tokenStorage from "utils/tokenStorage";

export type AccessType = "closed" | "not-start" | "started" | null;

type AuthState = {
  isLoggedIn: boolean;
  isPreview: boolean;
  enterTime: number;
  endTime: number;
  accessType: AccessType;
  previewRoomId: string | null;
};

const previewToken = previewTokenStorage.getToken();

const initialState: AuthState = {
  isLoggedIn: !!tokenStorage.getToken() || !!previewToken,
  isPreview: !!previewToken,
  enterTime: -1,
  endTime: -1,
  accessType: null,
  previewRoomId: previewRoomStorage.getRoomId() || null,
};

const { reducer, actions } = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logIn(state) {
      state.isLoggedIn = true;
      return state;
    },
    logInAsAdmin(state) {
      state.isLoggedIn = true;
      state.isPreview = true;
      return state;
    },
    logOutAsAdmin(state) {
      state.previewRoomId = null;
      state.isLoggedIn = false;
      state.isPreview = false;
      return state;
    },
    setPreviewRoom(state, action: PayloadAction<string | null>) {
      state.previewRoomId = action.payload;
      return state;
    },
    logOut(state) {
      state.isLoggedIn = false;
      return state;
    },
    setAccessTime(
      state,
      action: PayloadAction<{
        enterTime: number;
        endTime: number;
        accessType: AccessType;
      }>
    ) {
      const { enterTime, endTime, accessType } = action.payload;
      state.enterTime = enterTime;
      state.endTime = endTime;
      state.accessType = accessType;
    },
    acceptAccess(state) {
      state.accessType = "started";
    },
    closeAccess(state) {
      state.accessType = "closed";
    },
  },
});

export const logOutType = actions.logOut.type;

export default reducer;

let accessTimeout: number;
let closeTimeout: number;

export const setAccessTime = (
  enterDate: string | null,
  endDate: string
): AppThunk => (dispatch) => {
  let accessType: AccessType = "started",
    enterTime = -1,
    endTime = new Date(endDate).getTime();

  function dispatchAccessTime() {
    dispatch(
      actions.setAccessTime({
        accessType: accessType,
        endTime,
        enterTime,
      })
    );
  }
  /*
  If the entr date is null, then the fairies are already over.
  Show the close event screen all the time 
  */
  if (enterDate === null) {
    accessType = "closed";
    dispatchAccessTime();
    return;
  }

  /*
  The timestamp of the login time is calculated,
  and we check if the user can log in now
  */
  const now = Date.now();
  enterTime = new Date(enterDate).getTime();
  const begginingHasCome = now >= enterTime;
  const endingHasCome = now >= endTime;

  if (endingHasCome) {
    accessType = "closed";
  } else if (!begginingHasCome) {
    /*
    if the beginning has not yet arrived,
    set a timer that opens access at the appointed time
    */

    accessType = "not-start";
    const timeountTime = enterTime - now;
    accessTimeout = setLongTimeout(() => {
      dispatch(actions.acceptAccess());
    }, timeountTime);
  } else {
    /*
    if fairs started, run close event observer
    */
    const timeountTime = endTime - now;
    closeTimeout = setLongTimeout(() => {
      dispatch(actions.closeAccess());
    }, timeountTime);
  }

  dispatchAccessTime();
};

export const logIn = (token: string): AppThunk => (dispatch) => {
  tokenStorage.setToken(token);
  dispatch(actions.logIn());
};

export const loginAsAdmin = (token: string): AppThunk => (dispatch) => {
  previewTokenStorage.setToken(token);
  dispatch(actions.logInAsAdmin());
};

export const setPreviewRoom = (room: string | null): AppThunk => (dispatch) => {
  if (!room) previewRoomStorage.removeRoomId();
  else previewRoomStorage.setRoomId(room);
  dispatch(actions.setPreviewRoom(room));
};

export const logoutAsAdmin = (): AppThunk => (dispatch) => {
  previewTokenStorage.invalidateToken();
  dispatch(actions.logOutAsAdmin());
};

export const logOut = (): AppThunk => (dispatch) => {
  tokenStorage.invalidateToken();
  dispatch(actions.logOut());
  if (accessTimeout) {
    clearLongTimeout(accessTimeout);
  }
  if (closeTimeout) {
    clearLongTimeout(closeTimeout);
  }
};
