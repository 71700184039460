import { styled } from "@linaria/react";
import clsx from "clsx";
import React, { PropsWithChildren, useCallback } from "react";
import {
  NestedNavigationProviderHOC,
  useNestedNavigation,
} from "./components/NestedNavigationProvider";

export type NestedNavigationProps = PropsWithChildren<{
  className?: string;
}>;

const Root = styled.div`
  height: 100%;
`;

const NestedNavigation = NestedNavigationProviderHOC(
  ({ className, ...props }: NestedNavigationProps) => {
    const {
      mouseOverNavigationRef,
      selectSection,
      selectedSection,
    } = useNestedNavigation();

    const handleSubheaderMouseEnter = useCallback(() => {
      mouseOverNavigationRef.current = true;
    }, [mouseOverNavigationRef]);

    const handleSubheaderMouseLeave = useCallback(() => {
      mouseOverNavigationRef.current = false;
      selectSection(undefined);
    }, [mouseOverNavigationRef, selectSection]);

    return (
      <Root
        {...props}
        className={clsx(className, {
          expanded: !!selectedSection,
        })}
        onMouseEnter={handleSubheaderMouseEnter}
        onMouseLeave={handleSubheaderMouseLeave}
      />
    );
  }
);

export default NestedNavigation;
