import { styled } from "@linaria/react";
import { colors, zIndex } from "styles";

const SearchBaseMenu = styled.div`
  position: relative;
  min-width: 273px;
  padding: 24px 17px 18px 20px;

  background-color: #fff;
  z-index: ${zIndex.popover};
  border-color: #000;

  border-radius: 0px 0px 10px 10px;
`;
export default SearchBaseMenu;
