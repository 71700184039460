import { api } from "index";
import { useQuery, UseQueryOptions } from "react-query";
import { VIEWING_ROOM_CACHE_KEY } from "../api.viewingroom";
import { ViewingRoomItem } from "../interfaces/viewing-room.interfaces";
import useViewingRoomId from "./useViewingRoomId";

const fetchViewingRoomRequest = async (id?: string) => {
  const response = await api
    .get(`/api/v1/user/viewing-rooms/${id}`)
    .json<{ item: ViewingRoomItem }>();
  return response.item;
};

export default function useViewingRoom(
  id?: string | number,
  config?: UseQueryOptions<ViewingRoomItem>
) {
  const currentVrId = useViewingRoomId();

  id = id || currentVrId;

  const result = useQuery(
    [VIEWING_ROOM_CACHE_KEY, id.toString()],
    () => fetchViewingRoomRequest(id?.toString()),
    {
      enabled: typeof config?.enabled === "boolean" ? config?.enabled : !!id,
      staleTime: 15 * 60 * 1000,
      ...config,
    }
  );

  return { ...result, id };
}
