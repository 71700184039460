import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AnalyticsClient } from "./AnalyticsClient";

const ChatContext = React.createContext<{
  client: AnalyticsClient;
  connected: boolean;
} | null>(null);

const client = new AnalyticsClient();

export const AnalyticsClientProvider = (props: PropsWithChildren<{}>) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const openListener = () => {
      setConnected(true);
    };
    const closeListener = () => setConnected(false);
    client.openEvent.addListener(openListener);
    client.closeEvent.addListener(closeListener);
    return () => client.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    client.connectToServer();

    return () => client.close();
  }, []);

  const value = useMemo(() => ({ client, connected }), [connected]);

  return <ChatContext.Provider value={value} {...props} />;
};

export const useAnalyticsClient = () => {
  const context = useContext(ChatContext);
  if (context === null) {
    throw new Error("No analytics client in contentext");
  }
  return context;
};
