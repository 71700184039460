import { normalizeToLocation } from "features/Navigation/hooks/useGoBackTo";
import { Location } from "history";
import React from "react";
import {
  Redirect,
  RedirectProps,
  Route,
  RouteProps,
  useLocation
} from "react-router-dom";
import { stringify } from "utils/query-string";

interface NextProps {
  to: RedirectProps["to"];
}

export function getNextSearch<T>(location: Location<T>) {
  const next = encodeURI(location.pathname).concat(
    "?",
    location.search.replace("?", "")
  );
  return stringify({ next });
}

const Next = ({ to }: NextProps) => {
  const location = useLocation();
  const newTo = normalizeToLocation(to, location);
  const search = getNextSearch(location);

  return <Redirect to={{ ...newTo, search }} />;
};

interface NextRedirectProps {
  to: RedirectProps["to"];
  path?: RouteProps["path"];
}

const NextRedirect = ({ to, path }: NextRedirectProps) => {
  return (
    <Route path={path}>
      <Next to={to} />
    </Route>
  );
};

export default NextRedirect;
