import { useEffect, useState } from "react";

export default function useHandleOutsideClick<T extends HTMLElement>(
  onClick?: () => void,
  enabled: boolean = false
) {
  const [ref, setRef] = useState<T | null>(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref !== null && !ref.contains(event.target as any) && enabled) {
        onClick && onClick();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [enabled, onClick, ref]);

  return setRef;
}
