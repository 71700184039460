import clsx from "clsx";
import SvgCross from "components/icons/Cross";
import SvgSearchDesktop from "components/icons/SearchDesktop";
import React from "react";
import { HeaderButtonProps } from "../header.interfaces";
import HeaderAction from "./HeaderAction";

const SearchButton = ({
  active,
  onClick,
  onEnter,
  className,
}: HeaderButtonProps) => {
  return (
    <HeaderAction
      onClick={onClick}
      onMouseEnter={onEnter}
      className={clsx(className, !active ? "search" : "cross")}
      aria-label={!active ? "search" : "close search"}
    >
      {!active ? <SvgSearchDesktop /> : <SvgCross />}
    </HeaderAction>
  );
};

export default SearchButton;
