import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { logOut } from "../store/authSlice";

function pelcroLogout() {
  const Pelcro = (window as any).Pelcro;
  try {
    if (!!Pelcro?.user?.logout) {
      Pelcro.user.logout();
    }
  } catch {}
}

export default function useLogOut() {
  const dispatch = useDispatch();
  return useCallback(() => {
    pelcroLogout();
    dispatch(logOut());
  }, [dispatch]);
}
