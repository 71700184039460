import { styled } from "@linaria/react";

const RootLayout = styled.div`
  min-height: calc(var(--vh, 1vh) * 100);

  display: flex;
  flex-direction: column;
`;

export default RootLayout;
