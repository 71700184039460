import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export type NestedItemKey = string | number;

type ExpandedMap = Record<NestedItemKey, boolean>;

type NestedMenuValue = {
  expanded: ExpandedMap;
  expand: (key: NestedItemKey) => void;
  closeAll: () => void;
};

const NestedMenuContext = React.createContext<NestedMenuValue | null>(null);

type NestedMenuOptions = {
  singleExpanded?: boolean;
};

type NestedMenuProps = PropsWithChildren<NestedMenuOptions>;

const NestedMenu = ({ children, singleExpanded }: NestedMenuProps) => {
  const [expanded, setExpanded] = useState<ExpandedMap>({});

  const expand = useCallback(
    (key: NestedItemKey) => {
      if (singleExpanded) {
        setExpanded({ [key]: !expanded[key] });
      } else {
        setExpanded({ ...expanded, [key]: !!expanded[key] });
      }
    },
    [expanded, singleExpanded]
  );

  const closeAll = useCallback(() => {
    setExpanded({});
  }, []);

  const value = useMemo(() => ({ expanded, expand, closeAll }), [
    closeAll,
    expand,
    expanded,
  ]);

  return (
    <NestedMenuContext.Provider value={value}>
      {children}
    </NestedMenuContext.Provider>
  );
};

export default NestedMenu;

export function NestedMenuHOC<T>(options?: NestedMenuOptions) {
  return (Component: React.ComponentType<T>) => {
    return (props: T) => {
      return (
        <NestedMenu {...options}>
          <Component {...props} />
        </NestedMenu>
      );
    };
  };
}

export const useNestedMenu = () =>
  useContext(NestedMenuContext) as NestedMenuValue;
