import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  scrolls: Partial<Record<string, number>>;
} = {
  scrolls: {},
};

const { reducer, actions } = createSlice({
  name: "scrollController",
  initialState,
  reducers: {
    setScroll(state, action: PayloadAction<{ key: string; value: number }>) {
      const { key, value } = action.payload;
      state.scrolls[key] = value;
    },
    clearScroll(state, action: PayloadAction<{ key: string }>) {
      state.scrolls[action.payload.key] = undefined;
    },
  },
});

export default reducer;

export const { setScroll, clearScroll } = actions;
