import { homeRouteConfig } from "features/Home/home.routes";
import { viewingRoomRoutes } from "features/ViewingRoom/viewing-room.routes";
import LRUCache from "lru-cache";
import { getScrollContainer } from "utils/fixedBodyHeight";

const store = new LRUCache<string, number>({
  max: 100,
  maxAge: 1000 * 1000 * 1000,
});

export const INITIAL_SCROLL_KEY = "initialKey";

export function getScrollId() {
  return new Date().getTime().toString();
}

export function saveScrollPosition(scrollId: string) {
  const scrollPosition = getScrollContainer().scrollTop;
  store.set(scrollId, scrollPosition);
}

export function restoreScrollPosition(id: string) {
  const scrollTop = store.get(id);
  setTimeout(() => window.scrollTo(0, scrollTop ?? 0));
}

export const config = {
  restore: [homeRouteConfig.link, viewingRoomRoutes.path],
};
