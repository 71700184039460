export type Artwork = {
  galleryId: string;
  artistId?: number;
  Artist: { firstName: string; lastName: string };
  viewingRoomId: number;
  year: number;
  Medium: { name: string };
  previewOriginal?: string;
  previewMobile?: string;
  previewDesktop?: string;
  shortDescription: string;
  provenance: string;
  Currency: {
    id: number;
    code: string;
    country: string;
    symbol: string;
    createdAt: string;
    updatedAt: string;
  };
  _id: string;
  id: string;
  name: string;
  //На скрине это WATERCOLOR ON PAPER
  medium: string;
  //На скрине это EDITION OF 50
  edition?: string;
  //Если True, в деталке арта первый экран карусели должен показывать оригинал картины в комнате со стулом. Если нет, сразу photoPreviews, или video
  wallMounted: true;
  isWallMounted: true;
  //На дизайне нет. Пока не отображаем
  duration: number | null;
  //media_id видео для JW Player
  video?: string;
  //Число. Соответствует индексу выбранного значения из массива констант цен. Константы сервера лежат в /api/v1/user/get-constants.
  //get-constants вернет объект в котором будет поле { "priceRange": ["Under $10k", "$10-20k", ...и т.д.]}. Вот выбираем строку по индексу
  //и показываем как поле.
  //ВАЖНО ЧТО СТОИМОСТЬ МЫ ПОКАЗВАЕМ ТОЛЬКО ЕСЛИ В ДАННОМ ШОУ СТОИТ ФЛАГ allowPricing: true
  priceRange: number;
  //Конкретная стоимость. Переопределяет поле, выше. Т.е. если у нас указан price, мы игнорируем priceRange и выводим price
  price: number;
  salesStatusId: number;
  isVideoFirst: boolean;
  fullDescription: string;
  //Путь к оригиналу арта
  fileName: string;
  metaData: {
    author: string;
    creationDate: string;
    artistBirthYear: number;
    //Нигде не отображается, используется для поиска
    //Индекс соответствует значение из массива серверных коснтант
    //{ "artistGender": ["Femaile", "Male", ...etc]}.
    //Логика как в priceRange
    artistGender: number | null;
  };
  //ID шоу в которых показывается данный арт. Сейчас длинна массива может быть от 0 до 1
  showRef: string[];
  //Размеры в сантиметрах
  physicalDimensions: {
    h: number;
    w: number;
    d: number | null;
  };
  physicalDepth: number;
  physicalWidth: number;
  physicalHeight: number;
  //Фото превью арта
  photoPreviews: Array<string>;
  isDark: boolean;

  //Сгенерированное серваком превью картинки, чтобы экономить трафик и быстрее грузить
  //Десктопная картинка имеет до 1200px по большей стороне. Мобильная 700px по большей стороне.
  //Превью используются для отображения везде, кроме арт румы. В руме на стене - оригинал.
  desktopFileName: string;
  mobileFileName: string;
  //То же самое, как и Gender. Нигде не отображается, используется для поиска
  catogory: number | null;
};

export const ARTWORK_CACHE_KEY = "artwork";
export const ARTWORK_CACHE_KEY_INFINITE = "artwork-infinite";
