import * as React from "react";

const SvgContextNavigationArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="outlined"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 1l9 7.047-9 6.862" />
  </svg>
);

export default SvgContextNavigationArrow;
