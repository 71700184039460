import { NestedItemKey, useNestedMenu } from "components/NestedMenu/NestedMenu";
import useHandleOutsideClick from "hooks/useHandleOutsideClick";
import React, { useCallback } from "react";

interface ClickOutsideHandlerProps extends React.HTMLAttributes<HTMLElement> {
  name: NestedItemKey;
}

export default function ClickOutsideHander({
  name,
  ...props
}: ClickOutsideHandlerProps) {
  const { closeAll, expanded} = useNestedMenu();
  const ref = useHandleOutsideClick(closeAll, expanded[name]);

  const handleEndHover = useCallback(() => {
    closeAll()
  }, [closeAll])

  return <div ref={ref} onMouseLeave={handleEndHover} {...props} />;
}
