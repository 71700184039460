import React from "react";
import { styled } from "@linaria/react";
import clsx from "clsx";
import SubheaderBase from "features/Header/SubheaderBase/SubheaderBase";
import NavigationLink from "features/Navigation/components/NavigationLink";
import useDisableLinkHandler from "hooks/useDisableLinkHandler";
import { colors } from "styles";
import { up } from "styles/utils/breakpoints";
import { ContextNavigationOptions } from "../context-navigation.interfaces";
import clearMetaText from "../utils/clearMetaText";

type MobileContextNavigationTypes = "section" | "galleries" | "artworks" | "account";
interface ContextNavigationProps extends ContextNavigationOptions {
  className?: string;
  title: string;
  type?: MobileContextNavigationTypes;
}

const Root = styled(SubheaderBase)`
  position: fixed;
  left: 0;
  top: 100px;
  width: 100%;
  z-index: 100;
  justify-content: space-between;
  padding: 0 18px;
  border-block: 1px solid black;
  ${up("lg")} {
    padding: 0 30px;
  }
  .dark & {
    background-color: ${colors.dark};
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const NavMetaText = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-family: "Open Sans";
  letter-spacing: 0.35px;
  text-transform: uppercase;
`

const NavMetaItem = styled(NavMetaText)`
  display: flex;
  margin-left: 6px;

  &.title {
    max-width: calc(100vw - 220px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:first-child {
    margin-left: 0px;
  }
`

const NavSection = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    margin-right: 20px;
  }
`

const NavActionButton = styled(NavigationLink)`
  display: flex;
  cursor: pointer;
  &.disabled {
    opacity: 0.5;
  }
`

const NavLinkGroup = styled.div`
  display: flex;
  margin-left: 8px;
`

const VSpacer = styled(NavMetaText)`
  margin-left: 10px;
  margin-right: 10px;
`

const NavigationPlaceholder = styled(SubheaderBase)`
  position: relative;
`

export const ContextNavigationMobile = ({
  prevLink,
  nextLink,
  className,
  title,
  type = "section"
}: ContextNavigationProps) => {
  const disabledHandler = useDisableLinkHandler();

  return (
    <>
      <Root className={className}>
        <InnerWrapper>
          <NavSection>
            { type && <NavMetaItem>{type}:</NavMetaItem> }
            <NavMetaItem className="title">{clearMetaText(title)}</NavMetaItem>
          </NavSection>


          <NavSection>
            <NavLinkGroup>
              <NavActionButton onClick={!prevLink ? disabledHandler : undefined} replace to={prevLink ?? ""} className={clsx({ disabled: !prevLink })}>
                <NavMetaText>PREV</NavMetaText>
              </NavActionButton>
              <VSpacer>|</VSpacer>
              <NavActionButton onClick={!nextLink ? disabledHandler : undefined} replace to={nextLink ?? ""} className={clsx({ disabled: !nextLink })}>
                <NavMetaText>NEXT</NavMetaText>
              </NavActionButton>
            </NavLinkGroup>
          </NavSection>
        </InnerWrapper>
      </Root>
      <NavigationPlaceholder />
    </>
  );
};
