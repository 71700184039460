import { css } from "@linaria/core";

export const snackbarAnimationCss = css`
  :global() {
    .snackbar-enter {
      opacity: 0;
    }
    .snackbar-enter-active {
      opacity: 1;
    }
    .snackbar-enter-done {
      opacity: 1;
    }

    .snackbar-exit-active {
      opacity: 0;
    }
    .snackbar-exit {
      opacity: 0;
    }
    .snackbar-exit-done {
      opacity: 0;
    }
  }
`;

export const snackbarAnimationClass = "snackbar";
